@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?irefiz');
  src: url('/fonts/icomoon.eot?irefiz#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.woff2?irefiz') format('woff2'),
    url('/fonts/icomoon.ttf?irefiz') format('truetype'),
    url('/fonts/icomoon.woff?irefiz') format('woff'),
    url('/fonts/icomoon.svg?irefiz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'PP Fragment';
  /* src: url('/fonts/PPFragment-Light.otf'); */
  src: url('/fonts/PPFragment-Light.otf') format('opentype'),
    url('/fonts/PPFragment-Regular.otf') format('opentype'),
    url('/fonts/PPFragment-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.font-fragment {
  font-family: 'PP Fragment' !important;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-image:before {
  content: '\e93f';
}

.icon-target-arrow:before {
  content: '\e939';
}

.icon-data-pie:before {
  content: '\e93a';
}

.icon-data-histogram:before {
  content: '\e93b';
}

.icon-arrow-clockwise:before {
  content: '\e93c';
}

.icon-search:before {
  content: '\e93d';
}

.icon-star:before {
  content: '\e93e';
}

.icon-settings-line:before {
  content: '\e938';
}

.icon-eye-line:before {
  content: '\e937';
}

.icon-caret:before {
  content: '\e936';
}

.icon-arrow-sort:before {
  content: '\e92f';
}

.icon-close:before {
  content: '\e930';
}

.icon-wrench:before {
  content: '\e931';
}

.icon-briefcase:before {
  content: '\e932';
}

.icon-clipboard-text:before {
  content: '\e933';
}

.icon-megaphone-loud:before {
  content: '\e934';
}

.icon-play-circle:before {
  content: '\e935';
}

.icon-eye-off-fill:before {
  content: '\e92d';
}

.icon-eye-off:before {
  content: '\e92e';
}

.icon-open:before {
  content: '\e92c';
}

.icon-dot:before {
  content: '\e92c';
}

.icon-calendar-line:before {
  content: '\e92b';
}

.icon-add-circle-line:before {
  content: '\e92a';
}

.icon-copy-line:before {
  content: '\e928';
}

.icon-chevron-right:before {
  content: '\e929';
}

.icon-calendar:before {
  content: '\e921';
}

.icon-filter:before {
  content: '\e922';
}

.icon-checkmark-circle:before {
  content: '\e920';
}

.icon-checkmark:before {
  content: "\ea10";
}

.icon-chevron:before {
  content: '\e923';
}

.icon-copy:before {
  content: '\e924';
}

.icon-Info-filled:before {
  content: '\e925';
}

.icon-tiktok:before {
  content: '\e926';
}

.icon-facebook_b_w:before {
  content: '\e927';
}

.icon-cloud-link:before {
  content: '\e919';
}

.icon-calendar-data-bar:before {
  content: '\e91a';
}

.icon-sign-out:before {
  content: '\e91b';
}

.icon-building-retai-more:before {
  content: '\e91c';
}

.icon-people-settings:before {
  content: '\e91d';
}

.icon-integration:before {
  content: '\e91e';
}

.icon-wallet:before {
  content: '\e91f';
}

.icon-delete:before {
  content: '\e917';
}

.icon-person-add:before {
  content: '\e918';
}

.icon-edit-fill:before {
  content: '\e916';
}

.icon-image-add:before {
  content: '\e915';
}

.icon-eye:before {
  content: '\e914';
}

.icon-add-circle:before {
  content: '\e911';
}

.icon-dismiss:before {
  content: '\e912';
}

.icon-connect:before {
  content: '\e913';
}

.icon-dismiss-circle:before {
  content: '\e90e';
}

.icon-arrow-download:before {
  content: '\e90f';
}

.icon-spark:before {
  content: '\e910';
}

.icon-chevron-left:before {
  content: '\e90d';
}

.icon-edit:before {
  content: '\e90b';
}

.icon-cloud:before {
  content: '\e90c';
}

.icon-document-bullet-list-clock:before {
  content: '\e900';
}

.icon-key:before {
  content: '\e901';
}

.icon-person:before {
  content: '\e902';
}

.icon-arrow-down:before {
  content: '\e903';
}

.icon-shape:before {
  content: '\e904';
}

.icon-settings:before {
  content: '\e905';
}

.icon-peopl-team:before {
  content: '\e906';
}

.icon-database-lightning:before {
  content: '\e907';
}

.icon-data:before {
  content: '\e908';
}

.icon-data-area:before {
  content: '\e909';
}

.icon-home:before {
  content: '\e90a';
}

.icon-open:before {
  content: '\e92c';
}