/* @tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


label[data-bs-toggle]:hover > .popover {
  opacity: 1;
}

/* We can resend the mail or you can try later */

.bg-blue-500 {
  background-color: rgba(71, 84, 241, 1);
}

.bg-blue-100 {
  background-color: rgb(199, 202, 245);
}

.bg-gradient {
  background: linear-gradient(
    160deg,
    rgba(99, 184, 205, 1) 0%,
    rgba(67, 194, 208, 1) 50%,
    rgba(213, 147, 204, 1) 100%
  );
}

.bg-app {
  background: #f8fbfc;
}

.border-gray-300 {
  border-color: rgb(209 213 219/1);
}

.text-gray-500 {
  color: rgb(107 114 128/1);
}

.text-gray-400 {
  color: rgb(156 163 175/1);
}

.cursor-resize:hover {
  cursor: col-resize;
}

.select-none {
  user-select: none;
}

.table-resizable {
  min-width: 1400px !important;
}

.table-lg-resizable {
  min-width: 2250px !important;
}

.text-blue-600,
div.footerDatePicker div:first-child {
  color: #4754f1;
}

div.footerDatePicker {
  padding: 10px;
}

div.footerDatePicker div:first-child {
  color: rgba(50, 196, 212, 1);
}

div.footerDatePicker div button {
  margin-left: auto;
  width: 135px;
  height: 36px;
}

div.transition-all.ease-out.duration-300.absolute.z-10 {
  z-index: 1000;
}

.trucate-text {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.relative.w-full.text-gray-700
  > button.absolute.right-0.h-full.px-3.text-gray-400 {
  display: none;
}

/* Estilo por defecto del checkbox */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-align: center;
  border: 1px solid;
}

input[type='checkbox']:checked {
  background-color: #4754f1;
  border-color: #4754f1;
}

input[type='checkbox']:checked::before {
  content: '\2713';
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
}

/** resize performance table */

.w-sidebar-screen {
  width: 92vw;
}

.performance-column-tooltip {
  max-width: 850px !important;
  top: 58px !important;
}

.step-width {
  width: 50px;
  height: 50px;
}

/** utm width ***/
.utm-w-modal {
  width: 606px;
}

@media (max-width: 1250px) {
  .flex-col-1250 {
    flex-direction: column;
  }

  .flex-col-1250 .widget-container {
    width: 100%;
  }
}

.break-word {
  word-wrap: break-word;
}

html {
  overflow: hidden;
}

.custom-z-drawer {
  z-index: 1000;
}

.logic-switcher .logic-switcher__item input {
  opacity: 0 !important;
}

.selected-performance-name {
  color: #551a8b;
}

/**

analytics best performing and comparision section animation

**/

.image-wrapper {
  position: relative;
  height: 350px;
  overflow: hidden;
}

.items {
  position: absolute;
  top: 246px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  background-color: #fff;
  padding-top: 8px;
}

.image-wrapper:hover .items {
  transform: translateY(50%);
}

.image-wrapper:hover .comparison-image {
  opacity: 1;
}

.carousel .rating__item {
  min-width: 10px !important;
}

.image-wrapper:hover .rating__item,
.carousel:hover .rating__item {
  margin-right: 0.125rem;
  min-width: 10px !important;
}

.carousel {
  width: 345px;
}

.carousel .slide {
  margin-right: 12px !important;
}

.carousel:hover .slide {
  width: 96% !important;
  transition: 0.5s;
}

.carousel ul {
  background-color: #fff !important;
}

.carousel li {
  text-align: left !important;
  min-width: 62% !important;
}

.carousel:hover li {
  min-width: 94% !important;
  margin-right: 8px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: #fff !important;
}

.carousel img {
  width: 350px;
  height: 100%;
}

@media screen and (min-width: 1930px) {
  .carousel {
    width: 100%;
    max-width: 450px !important;
  }

  .carousel img {
    width: 300px !important;
  }

  .carousel li {
    min-width: 60% !important;
  }

  .carousel:hover li {
    min-width: 86% !important;
    margin-right: 8px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* Vertically centers the content */
}

label {
  display: flex;
  align-items: center;
  /* Vertically centers the checkbox with the label text */
}

input[type='checkbox'] {
  margin-right: 5px;
  /* Add some space between the checkbox and the text */
}

/** connect selectively ads custom style **/

@media screen and (min-height: 700px) {
  .custom-select-list-h {
    max-height: 90px;
  }
}

@media screen and (min-height: 900px) {
  .custom-select-list-h {
    max-height: 200px;
  }
}

a:hover .custom-icon {
  fill: #5f666d;
}

a:hover .custom-icon.active,
.custom-icon.active {
  fill: #32c4d4;
}

.query-table th {
  background: transparent;
  padding: 12px 8px 12px 8px;
  font-weight: 500;
  color: var(--bs-gray-600);
}

.query-table td {
  color: black;
  padding: 12px;
}

.query-table table {
  border: 1px solid #ebeff3;
  border-collapse: collapse;
  box-shadow: 0px 4px 6px 0px #23062d12;
  border-radius: 12px;
}

.query-table th,
.query-table td {
  border-bottom: 1px solid #ebeff3;
}

.query-table table,
.query-table th,
.query-table td {
  border-collapse: collapse;
}

.onStatusHover:hover ~ .status-shadow {
  display: flex;
}

.status-shadow {
  box-shadow: 0px 4px 16px 0px #1f3a5a26;
  border-radius: 6px;
  padding: 8px 12px 8px 12px;
  border: 1px solid #ebeff3;
  display: none;
}

.sticky-row {
  box-shadow: 0px -2px 16px 0px #0000000d;
  border-bottom-width: 0;
  background-color: #f1f3f4;
  color: #5f666d;
  width: 100%;
}

.custom-tooltip-chart {
  position: relative;
}

.custom-tooltip-chart::before {
  content: '';
  background: #fff;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  position: absolute;
  display: block;
  bottom: -8px;
  left: 23px;
}

.custom-marker {
  width: 20px;
  height: 8px;
  border-radius: 20px;
}

.custom-marker-blue {
  background: #4754f1;
}

.custom-marker-teal {
  background: #76ecf6;
}

.custom-marker-purple {
  background: #9c47f1;
}

.custom-marker-gradient {
  background: linear-gradient(
    266deg,
    #77edf7 -41%,
    #4855f2 14.74%,
    #77edf7 89.84%
  );
}

.custom-marker-pink {
  background: #cfbaff;
  background: #4754f1;
}

.custom-marker-teal {
  background: #76ecf6;
}

.custom-marker-purple {
  background: #9c47f1;
}

.custom-marker-salmon {
  background: #e689d0;
}

.custom-marker-pink {
  background: #cfbaff;
}

.custom-marker-text {
  color: #737b8a;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.gradient {
  background: linear-gradient(
    266deg,
    #77edf7 -41%,
    #4855f2 14.74%,
    #77edf7 89.84%
  );
}

.horizontal-scroll-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.custom-scroll-bar::-webkit-scrollbar {
  height: 8px;
  width: 3px;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #4754f1;
  border-radius: 4px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background-color: #2f3b9e;
}

.custom-scroll-bar::-webkit-scrollbar-thumb:active {
  background-color: #1f296f;
}

.input-rounded {
  border-radius: 50px !important;
  background-color: #f1f3f4;
  border: inherit !important;
}

.text-area-rounded {
  border-radius: 8px !important;
  background-color: #f1f3f4;
  border: inherit !important;
}

.input-error {
  border: 1px solid #ff303e !important;
}

.input-select {
  background: #f1f3f4;
  height: 2.5rem;
  border-radius: 50px;
  border: none;
  padding: 0 1rem;
}

.label-blue {
  background-color: #e8e9fe;
  color: #4754f1;
}

.table-scroll-journey {
  display: inline-block;
  table-layout: auto;

  width: 100%;
}

.table-scroll-journey thead,
.table-scroll-journey tbody,
.table-scroll-journey tfoot {
  display: block;
  width: 100%;
}

.table-scroll-journey tbody {
  max-height: 500px;
  overflow-y: overlay;
}

.table-scroll-journey tr {
  display: flex;
  width: 100%;
}

.table-scroll-journey th {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
  display: block;
}

.table-scroll-journey tbody tr td {
  width: 100%;
  display: block;
}

.table-scroll-journey tbody::-webkit-scrollbar {
  height: 8px;
  width: 3px;
}

.rdw-option-wrapper {
  border: none !important;
  background: none !important;
}

.z-99 {
  z-index: 99;
}

/** performance table height custom **/

@media (min-height: 700px) {
  .custom-performance-h {
    max-height: 89vh;
  }

  .custom-table-scroll {
    max-height: 405px !important;
  }
}
@media (min-height: 900px) {
  .custom-performance-h {
    max-height: 93vh;
  }

  .custom-table-scroll {
    max-height: 550px !important;
  }
}

/** Video border **/

.video-border video {
  @apply rounded-xl;
}

.report-iframe {
  width: 1440px;
}

#reportModal {
  max-width: 100%;
}

#reportModal .modal-dialog {
  max-width: 1440px;
}

@media (max-width: 1440px) {
  #reportModal .modal-dialog {
    max-width: 1400px;
  }
  .report-iframe {
    width: 1400px;
  }
}

@media (max-width: 1024px) {
  #reportModal .modal-dialog {
    max-width: 700px;
  }
  .report-iframe {
    width: 700px;
  }
}
@media (max-width: 520px) {
  #reportModal .modal-dialog {
    max-width: 375px;
  }
  .report-iframe {
    width: 375px;
  }
}

.feedback-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #cbd2dd;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.feedback-radio:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 6px solid #4654f1;
}
